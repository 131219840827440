*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: arial, sans-serif;
  color: #333;
  min-width: 850px;
  background: #003 url(images/bg.png) repeat-y scroll left top;
  margin: 0;
  font-size: 95%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}
h1 {
  font-size: 1.802em;
}
h2 {
  font-size: 1.602em;
}
h3 {
  font-size: 1.424em;
}
h4 {
  font-size: 1.266em;
}
h5 {
  font-size: 1.125em;
}
h6 {
  font-size: 1em;
}

a {
  color: #f66;
  text-decoration: none;
}
a:hover {
  color: #36f;
  text-decoration: none;
}

table {
  width: 100%;
}

tr,
td,
th {
  text-align: left;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

.hidden {
  display: none;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.font-weight-bold {
  font-weight: bold;
}
.font-italic {
  font-style: italic;
}

.even,
.odd {
  padding: 2px;
  border-bottom: 2px groove #fff;
  font-size: 95%;
}

.plate {
  background-image: url(images/plate.png);
  border-left: 3px solid #999;
  border-right: 3px solid #999;
  color: #000;
}
.plate::before {
  content: "";
  display: block;
  height: 15px;
  background: url(images/plate_top.png) repeat-x left top;
}
.plate::after {
  content: "";
  display: block;
  height: 15px;
  background: url(images/plate_bottom.png) repeat-x left bottom;
}
.plate a {
  color: #036;
}
.plate a:hover {
  color: #36f;
}
.plate h4 {
  color: #369;
  font-weight: bold;
  border-bottom: 2px groove #fff;
}
.block .blockTitle {
  height: 21px;
  margin-bottom: 0.4rem;
  padding: 0 10px 0 32px;
  background: url(images/arrow.png) no-repeat 7px top;
  color: #369;
  line-height: 21px;
  font-weight: bold;
}
.block .blockContent {
  padding: 0 10px;
  font-size: 13px;
}

.plate td {
  padding: 0;
  vertical-align: top;
  font-family: Verdana, Arial, Helvetica, sans-serif, serif;
  line-height: 150%;
}

.plate th {
  padding: 4px;
  border-bottom: 2px groove #fff;
  vertical-align: middle;
  color: #66c;
}

.plate .head {
  padding: 5px;
  font-weight: bold;
  border-bottom: 2px groove #fff;
  font-size: 95%;
}

#header .left {
  float: left;
}
#header .logo {
  background-image: url(images/logo.png);
  height: 100px;
  width: 400px;
}
#header .logo a {
  display: block;
  width: 100%;
  height: 100%;
}
#header .right {
  margin: 5px 15px;
  text-align: right;
}
#header .searchInput {
  margin: 0 5px;
  width: 180px;
  background-color: #336;
  color: #ccc;
}
#header .searchButton {
  margin: 0 15px 0 0;
  border-top: 2px groove #999;
  border-left: 2px groove #999;
  border-right: 2px ridge #fff;
  border-bottom: 2px ridge #fff;
  background-color: #336;
  color: #ccc;
  font-size: 14px;
}

#footer {
  text-align: right;
  margin-top: 30px;
}
#footer .plate {
  border: none;
}
#footer .plate::before {
  height: 20px;
}
#footer .plate::after {
  height: 5px;
  background: none;
}
#footer .copyright {
  color: #366;
  font-size: 80%;
  margin-right: 10px;
}
#footer .links {
  margin-right: 10px;
}
#footer .links img {
  margin: 10px 3px;
}
#main {
  margin: 0px 5px;
  line-height: 1.5;
}

.leftcolumn {
  float: left;
  width: 230px;
}
.leftcolumn .block {
  margin: 10px 5px 10px 10px;
}
.centercolumn {
  float: right;
  width: calc(100% - 230px);
}
.centercolumn > .block,
.centercolumn > .plate {
  margin: 10px 10px 10px 5px;
}
.centerCcolumn > .block {
  margin: 10px 10px 10px 15px;
}
.centerLcolumn {
  float: left;
  width: 50%;
}
.centerLcolumn .block,
.centerRcolumn .block {
  margin: 10px;
}
.centerRcolumn {
  float: right;
  width: 50%;
}
.mainContent {
  margin: 0 20px;
}
.mainContent table.listTable {
  border-collapse: collapse !important;
}
.mainContent table td {
  vertical-align: top;
}

ul.mainmenu {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.mainmenu a,
.mainmenu a.menuTop,
.mainmenu a.menuMain,
.mainmenu a.menuSub {
  display: block;
  text-indent: 15px;
  color: #333;
  background: url(images/indent.png) no-repeat left 2px;
}
.mainmenu a:hover {
  color: #f00;
  background: url(images/indent_hover.png) no-repeat left 2px;
}

.welcome {
  color: #ccc;
  margin-bottom: 30px;
  font-size: 14px;
  min-height: 420px;
  background: url(images/brain-bike.png) no-repeat right bottom;
}
.welcome h3 {
  margin-top: 1.5em;
}
.welcome .articles {
  padding-left: 30px;
}
.welcome .articles .article .title {
  display: inline-block;
  max-width: calc(100% - 400px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.welcome .announce {
  max-width: calc(100% - 200px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
