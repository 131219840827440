.mediawiki {
  font-size: 0.875em;
  line-height: 1.6;
}
.mediawiki .mw-content-text {
  margin: 15px 0;
}
.mediawiki * {
  box-sizing: content-box;
}
.mediawiki h1,
.mediawiki h2,
.mediawiki h3,
.mediawiki h4,
.mediawiki h5,
.mediawiki h6 {
  color: black !important;
  font-weight: bold;
  page-break-after: avoid;
  background: none;
  margin: 0;
  overflow: hidden;
  padding-top: 0.5em;
  padding-bottom: 0.17em;
  border-bottom: 1px solid #aaa;
}
.mediawiki ul {
  line-height: 1.5em;
  margin: 0.3em 0 0 1.6em;
  padding: 0;
  list-style-type: disc;
  list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAANAQMAAABb8jbLAAAABlBMVEX///8AUow5QSOjAAAAAXRSTlMAQObYZgAAABNJREFUCB1jYEABBQw/wLCAgQEAGpIDyT0IVcsAAAAASUVORK5CYII=);
}
.mediawiki ol {
  line-height: 1.5em;
  margin: 0.3em 0 0 3.2em;
  padding: 0;
  list-style-image: none;
}
.mediawiki li {
  margin-bottom: 0.1em;
}
.mediawiki dt {
  font-weight: bold;
  margin-bottom: 0.1em;
}
.mediawiki dl {
  margin-top: 0.2em;
  margin-bottom: 0.5em;
}
.mediawiki dd {
  line-height: 1.5em;
  margin-left: 1.6em;
  margin-bottom: 0.1em;
}
.mediawiki q {
  font-style: italic;
}
.mediawiki pre,
.mediawiki code,
.mediawiki tt,
.mediawiki kbd,
.mediawiki samp,
.mediawiki .mw-code {
  font-family: monospace, Courier;
  padding: 1em 0;
  color: black;
  background-color: #f9f9f9;
  border: 1pt dashed black;
  white-space: pre;
  font-size: 8pt;
  overflow: auto;
}
.mediawiki table {
  font-size: 100%;
}
.mediawiki fieldset {
  border: 1px solid #2f6fab;
  margin: 1em 0 1em 0;
  padding: 0 1em 1em;
  line-height: 1.5em;
}
.mediawiki fieldset.nested {
  margin: 0 0 0.5em 0;
  padding: 0 0.5em 0.5em;
}
.mediawiki legend {
  padding: 0.5em;
  font-size: 95%;
}
.mediawiki p {
  margin: 1em 0;
  line-height: 1.2em;
}
.mediawiki pre,
.mediawiki .mw-code {
  border: 1pt dashed black;
  white-space: pre;
  font-size: 8pt;
  overflow: auto;
  padding: 1em 0;
  background: white;
  color: black;
}
.mediawiki table.small {
  font-size: 100%;
}
.mediawiki table.listing,
.mediawiki table.listing td {
  border: 1pt solid black;
  border-collapse: collapse;
}
.mediawiki mark {
  background-color: yellow;
  color: black;
}
.mediawiki .mw-content hr {
  height: 1px;
  color: #aaa;
  background-color: #aaa;
  border: 0;
  margin: 0.2em 0;
}
.mediawiki abbr[title],
.mediawiki .explain[title] {
  border-bottom: 1px dotted;
  cursor: help;
}
.mediawiki img.tex {
  vertical-align: middle;
}
.mediawiki span.texhtml {
  font-family: serif;
}
.mediawiki .center {
  width: 100%;
  text-align: center;
}
.mediawiki .center * {
  margin-left: auto;
  margin-right: auto;
}
.mediawiki .small {
  font-size: 94%;
}

.mediawiki .mw-content .thumbcaption {
  text-align: left;
}
.mediawiki .mw-infobox {
  border: 2px solid #ff7f00;
  margin: 0.5em;
  clear: left;
  overflow: hidden;
}
.mediawiki .mw-infobox-left {
  margin: 7px;
  float: left;
  width: 35px;
}
.mediawiki .mw-infobox-right {
  margin: 0.5em 0.5em 0.5em 49px;
}
.mediawiki .mw-datatable {
  border-collapse: collapse;
}
.mediawiki .mw-datatable,
.mediawiki .mw-datatable td,
.mediawiki .mw-datatable th {
  border: 1px solid #aaaaaa;
  padding: 0 0.15em 0 0.15em;
}
.mediawiki .mw-datatable th {
  background-color: #ddddff;
}
.mediawiki .mw-datatable td {
  background-color: #ffffff;
}
.mediawiki .mw-datatable tr:hover td {
  background-color: #eeeeff;
}

.mediawiki .mw-header .navigation a {
  margin: 0 5px;
}

.mediawiki .mw-content .toc {
  display: table;
  padding: 7px;
  border: 1px solid #aaa;
  background-color: #f9f9f9;
  font-size: 90%;
}
.mediawiki .mw-content .toc ul {
  margin: 0.3em 0;
  text-align: left;
  list-style-type: none;
  list-style-image: none;
  margin-left: 0;
  padding: 0;
  text-align: left;
}
.mediawiki .mw-content .toc ul ul {
  margin: 0 0 0 2em;
}
.mediawiki .mw-content .toc ul ul {
  margin: 0 0 0 2em;
}
.mediawiki .mw-content .toc .toctoggle {
  font-size: 94%;
}
.mediawiki .mw-content .toc h2 {
  display: inline;
  border: none;
  padding: 0;
  font-size: 100%;
  font-weight: bold;
}
.mediawiki .mw-content .toc #toctitle {
  text-align: center;
}
.mediawiki div.tright {
  margin: 0.5em 0 1.3em 1.4em;
}
.mediawiki div.thumb {
  margin-bottom: 0.5em;
  width: auto;
  background-color: transparent;
}

.mediawiki div.tleft {
  margin: 0.5em 1.4em 1.3em 0;
}
.mediawiki div.tright,
.mediawiki div.floatright,
.mediawiki table.floatright {
  clear: right;
  float: right;
}
.mediawiki div.thumb {
  margin-bottom: 0.5em;
  width: auto;
  background-color: transparent;
}
.mediawiki div.thumbinner {
  border: 1px solid #ccc;
  padding: 3px !important;
  background-color: #f9f9f9;
  font-size: 94%;
  text-align: center;
  overflow: hidden;
}
.mediawiki .thumbcaption {
  border: none;
  line-height: 1.4em;
  padding: 3px !important;
  font-size: 94%;
}
.mediawiki .thumbimage {
  border: 1px solid #ccc;
}
